* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 8px;
  opacity: 0.75;
}
/*
background-color: #b0b3b8;
background-color: #a7aeb4;
*/
html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-horizontal {
  background-color: #c4ccd6;
  border-radius: 1000px;
  width: 8px;
  opacity: 0.75;
}

html::-webkit-scrollbar-horizontal,
body::-webkit-scrollbar-horizontal,
div::-webkit-scrollbar-horizontal {
  background-color: #c4ccd6;
  border-radius: 0.75;
  opacity: 0.75;
}

.pagination {
  display: flex;
  justify-content: center;
  font-weight: 500;
}

ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
}
ul.pagination li {
  width: 32px;
  font-weight: 500;
  cursor: pointer;
  height: 32px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

ul.pagination li a {
  text-decoration: none;
  color: #1f2539;
  font-size: 1rem;
  font-weight: 700;
}

ul.pagination li.active {
  color: #223a88;
  background: #e7ebf8;
  font-weight: 700;
  > a {
    color: #223a88;
  }
}

ul.pagination li.active {
  background-color: #e7ebf8;
  color: #223a88;
}

.page-selection {
  width: 24px;
  height: 24px;
  color: #1f2539;
  font-weight: 700;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 1.6;
  font-size: 18px;
  background-color: #eaecf1;
  color: #252525;
}

a {
  color: #0070f3;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  /* max-width: 100%; */
  display: block;
}

button {
  border: 0;
  background-color: transparent;
  font-family: inherit;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
button {
  margin: 0;
  padding: 0;
}

.content-container {
  width: 100%;
  min-height: 100%;
  background-color: #f9fbfc;
  padding: 20px 30px 30px 250px;

  > .page-title {
    font-size: 32px;
    color: #263e89;
  }
}

/* table */
.table_container * {
  font-size: 15px;
}

.table tr:hover td {
  background-color: #d5dfeb;
  cursor: pointer;
}

.th {
  text-align: center;
  background-color: #263e89 !important;
  color: #fff !important;
}

.td {
  text-align: center;
}

.roundCheckbox {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 20px;
  height: 20px;
  background-color: rgba(38, 62, 137, 0.5);
  color: #fff;
  border: 1px solid #ced4da;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}

.roundCheckbox:checked::after {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background-color: #fff;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

@font-face {
font-family: '__Pretendard_96a040';
src: url(/_next/static/media/773f599c24aa7e74-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__Pretendard_Fallback_96a040';src: local("Arial");ascent-override: 93.41%;descent-override: 22.67%;line-gap-override: 0.00%;size-adjust: 103.40%
}.__className_96a040 {font-family: '__Pretendard_96a040', '__Pretendard_Fallback_96a040'
}

